import React from "react";

function About() {
  return (
    <div>
      <div className="w-full h-screen bg-stone-100 flex items-center justify-center">
        <h2 className="text-3xl text-black">Another Section</h2>
      </div>
    </div>
  );
}

export default About;
