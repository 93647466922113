import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import React from "react";

const events = [{ title: "Meeting", start: new Date() }];

function Cours() {
  return (
    <div className="w-full z-20 p-16 min-h-screen flex justify-center items-center">
      <h1>Nos Cours</h1>
      <div className="w-2/4">
        <div className="w-full">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            weekends={false}
            events={events}
            eventContent={renderEventContent}
          />
        </div>
      </div>
    </div>
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

export default Cours;
