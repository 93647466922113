import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import EmojiLogo from "../components/EmojiLogo";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)",
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)",
  },
};

const icon_fitness = {
  hidden: {
    opacity: 0,
    fill: "rgba(255, 255, 255, 0)",
  },
  visible: {
    opacity: 1,
    fill: "rgba(255, 255, 255, 1)",
  },
};

const LogoAnimation = () => {
  const [scale, setScale] = useState(1);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [isVisible, setIsVisible] = useState(true);

  const [currentPath, setCurrentPath] = useState("");
  const location = useLocation();

  // Track the current path whenever it changes
  useEffect(() => {
    setCurrentPath(location.pathname);
    console.log(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      // Adjust these values for the desired effect
      const newScale = Math.max(0.15, 1 - scrollTop / 500); // Shrink gradually
      const op = Math.max(0, 1 - scrollTop / 500); // Shrink gradually

      const newTranslateX = Math.min(0, scrollTop / 2); // Move left
      const newTranslateY = Math.min(0, scrollTop / 2); // Move up

      setScale(newScale);
      setOpacity(op);
      setTranslateX(newTranslateX);
      setTranslateY(newTranslateY);

      if (window.scrollY > window.innerHeight - 200) {
        setIsVisible(false);
        setTranslateX(newTranslateX - 50);
        setTranslateY(newTranslateY - 130);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="absolute sm:block lg:hidden lg: top-0 w-screen h-screen ">
        {location.pathname === "/Home" && (
          <button className="bg-slate-800 text-white absolute left-1/2 -translate-x-1/2 sm:bottom-32 md:bottom-16 z-20 title hover:bg-slate-100  hover:scale-110 hover:bg-opacity-20 duration-500 bg-opacity-30 p-4  border-2 rounded-xl text-4xl">
            PARTICIPER!
          </button>
        )}
      </div>

      {location.pathname !== "/Home" ? (
        <div
          className={`${scale < 0.5 && "z-20"} 
       flex h-screen justify-center items-center z-20 absolute left-0 top-0 `}
        >
          <motion.div
            style={{
              transform: `scale(0.15) translate(-15px, -130px)`,
              transition: "transform 0.1s ease-out",
              transformOrigin: "0% 0%",
              position: "fixed", // To keep it fixed as the page scrolls
              top: 0,
              left: 0,
            }}
            className="lg:w-1/2 sm:w-full h-full  flex justify-center relative"
          >
            <div className="w-4/5  flex justify-center items-center -rotate-[20deg]">
              <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 480"
                className="logoAnimation absolute flex justify-center items-center sm-shadow "
              >
                {" "}
                <motion.path
                  d="M322,32.5c110.18,0,199.5,89.32,199.5,199.5S432.18,431.5,322,431.5S122.5,342.18,122.5,232
			S211.82,32.5,322,32.5 M322,5.5c-30.57,0-60.23,5.99-88.17,17.81c-26.97,11.41-51.19,27.74-71.99,48.53
			c-20.79,20.79-37.12,45.01-48.53,71.99C101.49,171.77,95.5,201.43,95.5,232s5.99,60.23,17.81,88.17
			c11.41,26.97,27.74,51.19,48.53,71.99c20.79,20.79,45.01,37.12,71.99,48.53c27.94,11.82,57.61,17.81,88.17,17.81
			s60.23-5.99,88.17-17.81c26.97-11.41,51.19-27.74,71.99-48.53c20.79-20.79,37.12-45.01,48.53-71.99
			c11.82-27.94,17.81-57.61,17.81-88.17s-5.99-60.23-17.81-88.17c-11.41-26.97-27.74-51.19-48.53-71.99
			c-20.79-20.79-45.01-37.12-71.99-48.53C382.23,11.49,352.57,5.5,322,5.5L322,5.5z"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M163.5,142.5c-1.61,5.82-3,112-3,112c0.61,2.64,18.75,8.33,18,4l2-44c16.76,23.6,30.15,39.83,38,44
		c1.08,1.08,10.08-7.92,9-9l-30-47l32-55c1.4-1.87-10.6-10.87-12-9c-11.85,13.3-24.31,32.49-37,54v-46
		C181.32,143.03,164.32,139.03,163.5,142.5z"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M238.5,144.5l-1,109c0,5,19,10,19,5v-112C256.5,142.5,238.5,138.5,238.5,144.5z"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M309.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C293.23,178.91,295.5,165.5,309.5,160.5z
		"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M309.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
      c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
      c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C293.23,178.91,295.5,165.5,309.5,160.5z
      "
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M382.5,161.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C366.23,179.91,368.5,166.5,382.5,161.5z
		"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M456.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C440.23,178.91,442.5,165.5,456.5,160.5z
		"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
              </motion.svg>
            </div>
          </motion.div>
        </div>
      ) : (
        <div
          className={`${scale < 0.5 && "z-30"} 
       flex h-screen justify-center items-center z-10 absolute left-0 top-0  `}
        >
          <motion.div
            style={{
              transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
              transition: "transform 0.1s ease-out",
              transformOrigin: "0% 0%",
              position: "fixed", // To keep it fixed as the page scrolls
              top: 0,
              left: 0,
            }}
            className={`lg:w-1/2 sm:w-full   ${
              scale < 0.2 ? "sm:ml-4 lg:ml-0 pb-0" : "sm:pb-40 lg:pb-0"
            } h-full  flex justify-center relative `}
          >
            <div className="w-4/5  flex justify-center items-center -rotate-[20deg]">
              {opacity !== 0 && <EmojiLogo></EmojiLogo>}
              <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 480"
                className="logoAnimation absolute flex justify-center items-center sm-shadow "
              >
                {" "}
                <motion.path
                  d="M322,32.5c110.18,0,199.5,89.32,199.5,199.5S432.18,431.5,322,431.5S122.5,342.18,122.5,232
			S211.82,32.5,322,32.5 M322,5.5c-30.57,0-60.23,5.99-88.17,17.81c-26.97,11.41-51.19,27.74-71.99,48.53
			c-20.79,20.79-37.12,45.01-48.53,71.99C101.49,171.77,95.5,201.43,95.5,232s5.99,60.23,17.81,88.17
			c11.41,26.97,27.74,51.19,48.53,71.99c20.79,20.79,45.01,37.12,71.99,48.53c27.94,11.82,57.61,17.81,88.17,17.81
			s60.23-5.99,88.17-17.81c26.97-11.41,51.19-27.74,71.99-48.53c20.79-20.79,37.12-45.01,48.53-71.99
			c11.82-27.94,17.81-57.61,17.81-88.17s-5.99-60.23-17.81-88.17c-11.41-26.97-27.74-51.19-48.53-71.99
			c-20.79-20.79-45.01-37.12-71.99-48.53C382.23,11.49,352.57,5.5,322,5.5L322,5.5z"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M163.5,142.5c-1.61,5.82-3,112-3,112c0.61,2.64,18.75,8.33,18,4l2-44c16.76,23.6,30.15,39.83,38,44
		c1.08,1.08,10.08-7.92,9-9l-30-47l32-55c1.4-1.87-10.6-10.87-12-9c-11.85,13.3-24.31,32.49-37,54v-46
		C181.32,143.03,164.32,139.03,163.5,142.5z"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M238.5,144.5l-1,109c0,5,19,10,19,5v-112C256.5,142.5,238.5,138.5,238.5,144.5z"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M309.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C293.23,178.91,295.5,165.5,309.5,160.5z
		"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M309.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
      c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
      c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C293.23,178.91,295.5,165.5,309.5,160.5z
      "
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M382.5,161.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C366.23,179.91,368.5,166.5,382.5,161.5z
		"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M456.5,160.5c-0.53,11.34,2,22,9,26c0,0,13,6,14-3c1.6-11.08,1.05-21.45-5-30c-2-3-13-13-23-13
		c-13,0-40,35-19,57c13.72,14.02,24.43,26.63,24,34c0.37,10.51-5.17,14.81-13,10c-6.02-3.23-11.59-9.11-17-16c-2-2-7-2-9-1
		c-1,0-2,3,0,9c5.71,17.49,17.27,26.65,35,27c17,0,28-14,27-29c-0.91-9.12-6.28-20.47-28-40C440.23,178.91,442.5,165.5,456.5,160.5z
		"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
              </motion.svg>
            </div>
            {opacity !== 0 && (
              <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 480"
                className={`item_fitness ${
                  scale < 0.2 ? "pb-0" : "sm:pb-40 lg:pb-0"
                } absolute h-full translate-y-6 flex justify-center items-center sm-shadow `}
              >
                {" "}
                <motion.path
                  d="M158.83,442.76v29.32c0,1.04,6.37,2.76,6.37,0.35l0.49-12.76h8.82c1.47,0,1.47-5.18-1.96-5.18h-7.35v-7.59
          h11.27c0.98,0,0-4.83-4.41-4.83L158.83,442.76z"
                  variants={icon_fitness}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M206.82,442.42c0.17-1.66,7.03-1.32,6.86,0.35v30.01c0,1.73-6.86,1.04-6.86-1.38V442.42z"
                  variants={icon_fitness}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M251.45,447.24v24.15c-0.23,2.24,6.63,2.58,6.86,0.35l0.49-25.53l6.37-0.35c1.83-0.09,1.34-4.92-0.49-4.83
          c-7.94,0.36-16.16,0.63-20.58,2.07c-1.05,0.17,0,4.48,1.47,4.48L251.45,447.24z"
                  variants={icon_fitness}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M296.32,442.76v30.36c0,0.35,6.37,1.73,6.37-0.35v-16.56l11.27,15.87c0,0.88,5.88,0.88,5.88,0v-30.01
          c-0.06-1.61-6.9-2.09-6.86-0.69v19.67l-9.8-16.56C302.05,443.01,296.32,442.42,296.32,442.76z"
                  variants={icon_fitness}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M350.34,443.45c-0.62,9.8-0.69,19.17,0,27.94c0.13,1.14,0.84,1.89,2.94,1.73l15.19-1.38
          c0.98-0.35,0.45-4.81-2.94-4.48l-9.31,0.69v-8.63l10.78-0.69c2.45-0.35,0-4.83-0.98-4.83l-9.31,0.35v-6.9l9.8-0.69
          c1.96-0.35-0.49-4.83-2.45-4.83l-11.27,0.69C350.83,442.42,350.37,442.74,350.34,443.45z"
                  variants={icon_fitness}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M416.46,445.64c-0.23,3.2,0.87,6.2,3.93,7.33c0,0,5.68,1.69,6.12-0.85c0.7-3.12,0.46-6.04-2.19-8.45
          c-0.87-0.85-5.68-3.66-10.05-3.66c-5.68,0-17.49,9.86-8.31,16.06c6,3.95,10.68,7.5,10.49,9.58c0.16,2.96-2.26,4.17-5.68,2.82
          c-2.63-0.91-5.07-2.57-7.43-4.51c-0.87-0.56-3.06-0.56-3.93-0.28c-0.44,0-0.87,0.85,0,2.54c2.5,4.93,7.55,7.51,15.3,7.61
          c7.43,0,12.24-3.94,11.8-8.17c-0.4-2.57-2.75-5.77-12.24-11.27C409.35,450.82,410.34,447.04,416.46,445.64z"
                  variants={icon_fitness}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
                <motion.path
                  d="M474.4,446.33c-0.23,3.2,0.87,6.2,3.93,7.33c0,0,5.68,1.69,6.12-0.85c0.7-3.12,0.46-6.04-2.19-8.45
          c-0.87-0.85-5.68-3.66-10.05-3.66c-5.68,0-17.49,9.86-8.31,16.06c6,3.95,10.68,7.5,10.49,9.58c0.16,2.96-2.26,4.17-5.68,2.82
          c-2.63-0.91-5.07-2.57-7.43-4.51c-0.87-0.56-3.06-0.56-3.93-0.28c-0.44,0-0.87,0.85,0,2.54c2.5,4.93,7.55,7.51,15.3,7.61
          c7.43,0,12.24-3.94,11.8-8.17c-0.4-2.57-2.75-5.77-12.24-11.27C467.29,451.51,468.28,447.73,474.4,446.33z"
                  variants={icon_fitness}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: { duration: 2, ease: "easeInOut" },
                    fill: { delay: 2, duration: 1, ease: [1, 0, 0.8, 1] },
                  }}
                />
              </motion.svg>
            )}
          </motion.div>
        </div>
      )}
    </>
  );
};

export default LogoAnimation;
